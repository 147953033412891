
import { defineComponent, ref, onMounted, nextTick } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent ({
  setup() {
    onMounted(() => {
      window.removeEventListener('message', addListener, true)
      window.addEventListener('message', addListener)
    })

    const addListener = (e) => {
      console.log(e.data)
      if (e.data.type == 'update_price') {
        var item =  html.value.prices.filter((i) => {
          return i.no == e.data.data['id']
        })[0]

        item.price_code = e.data.data['price_code']
      } else if (e.data.type == 'html_ready') {
        init_price()
      }
    }

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const goToFolder = () => {
      router.push({ name: 'folder', params: {id: route.params.eppcm_id} })
    }

    const goToHtmlPrevie = () => {
      router.push({ name: 'html_preview', params: {eppcm_id: route.params.eppcm_id, id: route.params.id} })
    }

    const html = ref({} as any)

    const refIframe = ref({} as any)

    store
      .dispatch(Actions.GET_HTML, route.params.id)
      .then(() => {
        html.value = store.getters.currentHtml
        // nextTick(() => {
        //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
        // })
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const init_price = () => {
      if (refIframe.value != null) {
        refIframe.value.contentWindow.postMessage({type: 'init_price', data: JSON.parse(JSON.stringify(html.value.prices))}, '*')
      }
    }

    const save = (item) => {
      store
        .dispatch(Actions.UPDATE_HTML, {id: html.value.id, values: {html_prices_attributes: html.value.prices}})
        .then(() => {
          Swal.fire({
            text: "保存成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    return {
      goToFolder,
      goToHtmlPrevie,
      html,
      refIframe,
      save
    }
  }
})
