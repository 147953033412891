import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icon_back.svg'


const _hoisted_1 = { class: "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom px-md-4 main-title" }
const _hoisted_2 = { class: "btn-toolbar mb-2 mb-md-0" }
const _hoisted_3 = {
  class: "editor",
  id: "html_editor"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          style: {"cursor":"pointer"},
          src: _imports_0,
          class: "mr",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToFolder && _ctx.goToFolder(...args)))
        }),
        _createTextVNode(_toDisplayString(_ctx.html.name), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-sm btn-outline-secondary btn-gray",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToHtmlPrevie && _ctx.goToHtmlPrevie(...args)))
        }, " 预览 "),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-sm btn-outline-secondary btn-blue ml",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, " 保存 ")
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("iframe", {
        src: _ctx.html.tmp_file_url,
        style: {"width":"100%","height":"100vh"},
        ref: "refIframe"
      }, null, 8, _hoisted_4)
    ])
  ]))
}